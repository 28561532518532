<template>
  <v-card :style="`border-left: 4px solid ${card.color}60`" class="card-integration bg-white d-flex flex-column justify-space-between"
          width="270px">
    <span>
      <v-card-title>
        <v-tooltip content-class="title__tooltip" top>
          <template v-slot:activator="{ on }">
            <div v-on="on">{{ card.title | truncate(20) }}</div>
          </template>
          <span>{{ card.title }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-subtitle class="py-0">{{ card.subtitle }}</v-card-subtitle>
    </span>
    <v-card-text class="d-flex flex-column overflow-hidden py-0">
      <slot name="content"></slot>
    </v-card-text>
    <v-card-actions>
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    card: Object
  },
};
</script>

<style scoped lang="scss">
.v-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  overflow: auto !important;
  border-top: 4px solid var(--v-primary-base);

  .v-card__text {
    padding-bottom: 0;
  }
}

.card-integration {
  border-radius: 4px;
  transition: box-shadow .3s ease-in-out;
  color: #424242;
}

.card-integration:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
